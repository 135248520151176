<template>
  <div>
    <div class='cepContact A4Container flexCloumnCenter pageBreak_no'>
      <div class='form'>
        <el-row class=''>
          <el-col :span='4' class='flexCenter cell cell50'
            style='padding: 0 10px; border-left: 0;'>实习科室</el-col>
          <el-col :span='8' class='flexCenter cell cell50 overMoreLine'
            style='white-space: normal;word-break: break-all;padding: 0 5px;box-sizing: border-box;'>
            {{ info.department }}
          </el-col>
          <el-col :span='4' class='flexCenter cell cell50' style='padding: 0 10px;'>实习时间</el-col>
          <el-col :span='8' class='flexCenter cell cell50 overMoreLine'
            style='white-space: normal;word-break: break-all;padding: 0 5px;box-sizing: border-box;'>
            {{ info.start_time }}&nbsp;至&nbsp;{{ info.end_time }}
          </el-col>
        </el-row>




        <el-row class='flexStart' style='display: flex;'>
          <el-col :span='4' class='flexCenter cell cell50' style=' border-left: 0;'>出勤情况</el-col>
          <el-col :span='20' class='flexCenter cell cell50' style='padding: 0 5px;'>
            <div class='flex_1 flexAround' style='white-space: nowrap;'>
              <span class=''>病假：</span><span>{{ info.sick_leave || 0 }}天；</span>
            </div>
            <div class='flex_1 flexAround' style='white-space: nowrap;'>
              <span class=''>事假：</span><span>{{ info.personal_leave || 0 }}天；</span>
            </div>
            <div class='flex_1 flexAround' style='white-space: nowrap;'>
              <span class=''>旷工：</span><span>{{ info.absenteeism || 0 }}天；</span>
            </div>
            <div class='flex_1 flexAround' style='white-space: nowrap;'>
              <span class=''>迟到：</span><span>{{ info.be_late || 0 }}天；</span>
            </div>
            <div class='flex_1 flexAround' style='white-space: nowrap;'>
              <span class=''>早退：</span><span>{{ info.leave_early || 0 }}天；</span>
            </div>
          </el-col>
        </el-row>

        <el-row class='flexCenter cell cell50 borderB FS18' style='border-left: 0;'>
          一、实习自我小结
        </el-row>
        <el-row class='' style='width: 100%;height: calc( 100% - 568px );'>
          <div class='content'
            style='width:100%;height: 100%;overflow: hidden;box-sizing: border-box;position: relative;'>
            <div :id="'divCntnrA_' + idx" style='width:100%;height: calc( 100% - 50px );overflow: hidden;'>
              <div :id="'resizeDivA_' + idx" class="fontFamily_HWZS" style='width: 100%;word-break: break-all;white-space: pre-line;'>
                {{ info.content }}
              </div>
            </div>
            <div class='flexStart' style='position: absolute;bottom: 10px;right: 10px;line-height: unset!important;'>
              <div class='flexStart' style='line-height: unset!important;'>
                <div class='fontFamily_HWZS'>签名：</div>
                <img v-if='info.sign' :src='info.sign' alt='' style='width: 100px;max-height: 50px;'>
                <div v-else style='width: 100px;'></div>
              </div>
              <div class='flexStart fontFamily_HWZS' style='line-height: unset!important;'>
                <span class=''>日期：</span>{{ info.sign_date }}
              </div>
            </div>
          </div>

        </el-row>

        <el-row class='flexCenter cell cell50 FS18' style='border-left: 0;'>
          二、医德医风测评表
        </el-row>
        <el-row class='flexStretch'>
          <el-col :span='5' class='cellMore flexCenter' style='border-left: 0;'>项&nbsp;&nbsp;目</el-col>
          <el-col :span='7'>
            <div style='width: 100%;'>
              <el-row class='cell flexCenter'>评&nbsp;&nbsp;等</el-row>
              <el-row>
                <el-col :span='6' class='cell cell50 flexCenter'>好</el-col>
                <el-col :span='6' class='cell cell50 flexCenter'>较好</el-col>
                <el-col :span='6' class='cell cell50 flexCenter'>一般</el-col>
                <el-col :span='6' class='cell cell50 flexCenter'>差</el-col>
              </el-row>
              <el-row>
                <el-col :span='6' class='cell flexCenter'>(10分)</el-col>
                <el-col :span='6' class='cell flexCenter'>(8分)</el-col>
                <el-col :span='6' class='cell flexCenter'>(6分)</el-col>
                <el-col :span='6' class='cell flexCenter'>(0分)</el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span='5' class='cellMore flexCenter'>项&nbsp;&nbsp;目</el-col>
          <el-col :span='7'>
            <div style='width: 100%;'>
              <el-row class='cell flexCenter'>评&nbsp;&nbsp;等</el-row>
              <el-row>
                <el-col :span='6' class='cell cell50 flexCenter'>好</el-col>
                <el-col :span='6' class='cell cell50 flexCenter'>较好</el-col>
                <el-col :span='6' class='cell cell50 flexCenter'>一般</el-col>
                <el-col :span='6' class='cell cell50 flexCenter'>差</el-col>
              </el-row>
              <el-row>
                <el-col :span='6' class='cell flexCenter'>(10分)</el-col>
                <el-col :span='6' class='cell flexCenter'>(8分)</el-col>
                <el-col :span='6' class='cell flexCenter'>(6分)</el-col>
                <el-col :span='6' class='cell flexCenter'>(0分)</el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
        <el-row class='ydyf' style='flex-wrap: wrap;'>
          <el-col class='nth' :span='12' v-for='(item, index) in list'>
            <el-row>
              <el-col :span='10' class='flexCenter cell bl0'>{{ item.name }}</el-col>
              <el-col :span='14'>
                <el-row>
                  <el-col :span='6' class='flexCenter cell'>{{ item.value == 10 ? '✓' : '' }}</el-col>
                  <el-col :span='6' class='flexCenter cell'>{{ item.value == 8 ? '✓' : '' }}</el-col>
                  <el-col :span='6' class='flexCenter cell'>{{ item.value == 6 ? '✓' : '' }}</el-col>
                  <el-col :span='6' class='flexCenter cell'>{{ item.value == 0 ? '✓' : '' }}</el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span='17'>
            <el-row>
              <el-col :span='4' class='cell cell50 flexCenter' style='border-left: 0;'>累计分</el-col>
              <el-col :span='8' class='cell cell50 flexCenter'>{{ info.evaluation.totalyd }}</el-col>
              <el-col :span='5' class='cell cell50 flexCenter'>扣除分</el-col>
              <el-col :span='7' class='cell cell50 flexCenter'>{{ info.evaluation.kcScore||'' }}</el-col>
            </el-row>
          </el-col>
          <el-col :span='7'>
            <el-row>
              <el-col :span='12' class='cell cell50 flexCenter'>得分</el-col>
              <el-col :span='12' class='cell cell50 flexCenter'>{{ info.evaluation.finalScore }}</el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span='5' class='cell cell50 flexCenter' style='border-left: 0'>备&nbsp;&nbsp;注</el-col>
          <el-col :span='19' class='cell cell50 flexCenter'
            style="word-break: break-all;white-space:normal;line-height: unset!important;padding: 0 10px;">若实习期间发生事故，乱开病假条、向病人索要礼物、旷工、应按每发生一项
            在累计分中扣除10分。</el-col>
        </el-row>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'Keshi1',
  data() {
    return {
      dataover: false,
      userid: '',
      info: { evaluation: {} },
      list: [
        { name: '热爱专业虚心好学', value: 10 }, { name: '顾全大局团结协作', value: 10 },
        { name: '尊重病人一视同仁', value: 10 }, { name: '遵守纪律坚守岗位', value: 10 },
        { name: '工作负责服务周到', value: 10 }, { name: '诚实严谨保守医秘', value: 10 },
        { name: '语言文明礼貌待人', value: 10 }, { name: '着装规范仪表端庄', value: 10 },
        { name: '尊敬老师服从安排', value: 10 }, { name: '廉洁奉公不谋私利', value: 10 }
      ]
    }
  },
  props: {
    info1: {
      type: Object,
      default: {}
    },
    idx: {
      type: Number,
      default: 1
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let _this = this
      setTimeout(function () {
        let info = JSON.parse(JSON.stringify(_this.info1))
        // console.log(177, info)
        _this.info = { ...info }
        _this.list[0].value = info.evaluation.yd1
        _this.list[1].value = info.evaluation.yd6
        _this.list[2].value = info.evaluation.yd2
        _this.list[3].value = info.evaluation.yd7
        _this.list[4].value = info.evaluation.yd3
        _this.list[5].value = info.evaluation.yd8
        _this.list[6].value = info.evaluation.yd4
        _this.list[7].value = info.evaluation.yd9
        _this.list[8].value = info.evaluation.yd5
        _this.list[9].value = info.evaluation.yd10
        _this.$nextTick(() => {
          _this.resizeA()
        })
      }, 600)

    },
    stripscript(s) {
      var pattern = new RegExp('\n')
      var rs = ''
      for (var i = 0; i < s.length; i++) {
        rs = rs + s.substr(i, 1).replace(pattern, '')
      }
      return rs
    },
    resizeA() {
      let divHeight = document.getElementById(`divCntnrA_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
      // console.log(' divHeight, textHeight', divHeight, textHeight)
      let i = 1
      while (textHeight > divHeight && i < 5) {
        document.getElementById(`resizeDivA_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
        i++
      }
    },
    resizeB() {
      let divHeight = document.getElementById(`divCntnrB_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
      // console.log('b', this.idx, divHeight, textHeight)
      let i = 1
      while ((textHeight > divHeight) && i < 5) {
        document.getElementById(`resizeDivB_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
        i++
      }
    }
  }
}
</script>

<style lang="less" type="text/less">
.cepContact .el-col {
  display: flex;
  padding: 0;
  margin: 0;
}

.cepContact .el-row {
  width: 100%;
  padding: 0;
  margin: 0;
}
</style>
<style scoped="scoped" type="text/less" lang="less">
@font-face {
  font-family: "华文中宋";
  src: url("/public/font/hwzs.ttf");
}

.fontFamily_HWZS {
  font-family: '华文中宋';
}

.flexStretch {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.cepContact {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 25px 0;

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .ydyf {
    .nth:nth-of-type(2n+1) {
      .bl0 {
        border-left: 0;
      }
    }
  }

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    border: 1px solid #000;
    border-top: 0;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: break-spaces;
      word-break: break-all;
      overflow: hidden;
    }

    .el-row {
      //border-top: 1px solid #000;
      //border-bottom: 1px solid #000;
    }
  }

  .col-row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}


.cepContact {

  .table {
    width: 100%;
  }

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .FS18 {
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .cell {
    border-left: 1px solid #333;
    border-top: 1px solid #333;

    min-height: 30px;
    //line-height: 30px;
    //text-align: center;
    white-space: nowrap;
    font-size: 14px;
    font-family: "华文中宋";
    word-break: break-all;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-family: "华文中宋";
  }

  .cellMore {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    min-height: 50px;
    text-align: left;
    font-size: 14px;
    font-family: "华文中宋";
  }
}

.uline {
  text-decoration: underline;
}
</style>
